/*------------------------------
	last-update 2021/12/23
------------------------------*/
import $ from 'jquery';
import 'slick-carousel';

//ua取得
var ua = navigator.userAgent;
if (
  ua.indexOf('iPhone') > 0 ||
  (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) ||
  ua.indexOf('Windows Phone') > 0
) {
  var device = 'sp';
} else {
  var device = 'pc';
}

$(function () {
  
  //* ===================================================
  // Slick
  //==================================================== */
  // pc
  $('#js-slider').slick({
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 3000,
    dots: false,
    arrows: false,
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 1,
    variableWidth : false,
    infinite: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
  });
  
  // 100vh対策（min-heightを動的に設定する）
  $('body').each(function () {
    var viewHeight = $(window).height();
    document.documentElement.style.setProperty('--viewHeight', `${viewHeight}px`);
  });
  $('header').each(function () {
    var headerHeight = $(this).innerHeight();
    document.documentElement.style.setProperty('--headerHeight', `${headerHeight}px`);
  });
  $('footer').each(function () {
    var footerHeight = $(this).innerHeight();
    document.documentElement.style.setProperty('--footerHeight', `${footerHeight}px`);
  });

  if (device == 'pc') {
    navFixed();
    // mainの表示タイミング調整
    $(window).on('scroll', function () {
      var scroll_top = $(document).scrollTop();
      if (scroll_top != 0) {
        $('.l-main').addClass('start');
      }
    });
  } else if (device == 'sp') {
    drawer();
    spTelCalling();
  }
  smoothScroll();
  rollover();
  reload();
  pagetop();
  // modal();
  // accordion();
});


// navFixed
function navFixed() {
  $(window).on('scroll', function () {
		var position = $('header').offset().top;
    var scroll_top = $(document).scrollTop();
		// console.log('position='+ position);
		// console.log('scroll_top='+ scroll_top);
    if (scroll_top >= position) {
      $('.l-header__inner').addClass('fixed');
    } else if (scroll_top <= position) {
      $('.l-header__inner').removeClass('fixed');
    }
  });
}

// drawer
function drawer() {
  $('.l-spMenu .l-gnav__link').on('click', function () {
    $('#nav-input').prop('checked', false);
  });
  $('#nav-input').change(function () {
    var input = $(this).prop('checked');
    if (input) {
      $('body').addClass('scroll-none');
      $('.l-drawerBtn').addClass('drawer');
    } else {
      $('body').removeClass('scroll-none');
      $('.l-drawerBtn').removeClass('drawer');
    }
  });
}

// spTelCalling
function spTelCalling(target_tag_arr) {
  var target_tag_arr = target_tag_arr || ['span', 'strong'];
  $.each(target_tag_arr, function (i, v) {
    $(v).each(function () {
      var text;
      if (v === 'img') {
        text = $(this).attr('alt');
      } else {
        text = $(this).text();
      }
      var remove_space_text = text.replace(/\s+/g, '');
      if (
        remove_space_text.match(/(0[0-9]{1,4})-([0-9]{1,4})-([0-9]{3,4})/, 'g') &&
        remove_space_text.match(/^0[0-9\-]{6,7}-[0-9]{3,4}$/)
      ) {
        var tag_for_call = '<a class="spTelCalling" href="tel:' + remove_space_text + '">';
        if (v === 'img') {
          $(this).wrap(tag_for_call);
        } else {
          $(this).wrapInner(tag_for_call);
        }
      }
    });
  });
}

// smoothScroll
function smoothScroll() {
  var scroll = new SmoothScroll('a:not(.calendar)[href*="#"]', {
    duration: 500,
    easing: 'easeInOutQuint',
  });
}

// rollover
function rollover() {
  $('a img').each(function () {
    var imgSrc = $(this).attr('src');
    //画像がonの時notクラスを付ける
    if (imgSrc.match(/(.*)_on(\..*)/)) {
      $(this).attr('class', 'not');
    }
    //smartRollover
    if (imgSrc.match(/(.*)_off(\..*)/)) {
      var repSrc = RegExp.$1 + '_on' + RegExp.$2;
      $('<img>').attr('src', repSrc);
      $(this).hover(
        function () {
          $(this).attr('src', repSrc);
          $(this).css('opacity', 1);
        },
        function () {
          $(this).attr('src', imgSrc);
        }
      );
    }
  });
}

// reload
function reload() {
  var timer = 0;
  var currentWidth = window.innerWidth;
  $(window).resize(function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    if (timer > 0) {
      clearTimeout(timer);
    }

    timer = setTimeout(function () {
      location.reload();
    }, 200);
  });
}

// pagetop
function pagetop() {
  var pagetop = $('#pagetop');

  // 挙動
  pagetop.on('click', function () {
    $('body, html').animate({ scrollTop: 0 }, 500);
    return false;
  });

  // スクロールしたらボタン表示
  $(window).on('scroll', function () {
		var scroll_top = $(document).scrollTop();
		var height = $(window).innerHeight();
		var allHeight = $(document).innerHeight();
		var footer = $('footer').innerHeight();
		var stop = allHeight - footer - height;

		if (scroll_top > 500) {
			pagetop.addClass('fixed');
      pagetop.removeClass('stop');
		} else {
			pagetop.removeClass('fixed');
			pagetop.removeClass('stop');
		}
		if (scroll_top > stop) {
      pagetop.addClass('stop');
      pagetop.removeClass('fixed');
		}
  });
}

// modal
function modal() {
  var open = $('.p-modalBtn');
  var close = $('.p-modalBox .c-closeBtn');
  var modal = $('.p-modalBox');
  $(open).on('click', function () {
    $(modal).addClass('open');
    $(modal).removeClass('close');
    $('body').addClass('scroll-none');
  });
  $(close).on('click', function () {
    $(modal).removeClass('open');
    $(modal).addClass('close');
    $('body').removeClass('scroll-none');
  });
}

// accordion
function accordion() {
  var windowWidth = $(window).width();
  var breakPoint = 993;
  // accordion
  if (windowWidth >= breakPoint) {
    var accordion = $('.c-accordion');
    // var parent = $(accordion).children('.c-accordion__parent');
    // var list = $(parent).next('.c-accordion__list');
    $(accordion).hover(
      function () {
        $(this).addClass('open');
      },
      function () {
        $(this).removeClass('open');
      }
    );
  } else {
    $(parent).on('click', function () {
      $(this).toggleClass('open');
    });
  }
}
